import '@deity/falcon-client/node_modules/core-js';
import '@deity/falcon-client/node_modules/regenerator-runtime/runtime';
import 'app-webmanifest?suffix=';
import 'app-webmanifest?suffix=de_ch';
import 'app-webmanifest?suffix=fr';

import React from 'react';
import { hydrate, render } from 'react-dom';
import { ApolloProvider } from '@apollo/react-common'; // eslint-disable-line
import { loadableReady } from '@loadable/component';
import { I18nProvider } from '@deity/falcon-i18n';
import { getClientConfig } from '@deity/falcon-front-kit';
import { HelmetProvider } from 'react-helmet-async';
import { apolloClientWeb } from '@deity/falcon-client/src/service';
import HtmlHead from '@deity/falcon-client/src/components/HtmlHead';
import App, { clientApolloSchema } from '@deity/falcon-client/src/clientApp';
import i18nFactory from '@deity/falcon-client/src/i18n/i18nClientFactory';
import intlResolver from '../../helpers/IntlResolver/IntlResolver';
import { StoreProvider } from '../../elements/Store/StoreProvider';
import { DatadogIntegration } from '../../elements/Tracking/Datadog/Integration';
import BrowserRouter from '../../app/BrowserRouter';
import { LoadingBarContextProvider } from '../../hooks/LoadingBar';
import { getActiveStoreCode, getActiveStoreSuffix } from './Service/Utils/activeStore';

// eslint-disable-next-line no-underscore-dangle
const initialState = window.__APOLLO_STATE__ || {};
const clientConfig = getClientConfig(initialState) || {};
const { language } = intlResolver(window.location.pathname);
const i18nConfig = { ...clientConfig.i18n, lng: language };
const renderApp = clientConfig.serverSideRendering ? hydrate : render;
const storeSuffix = getActiveStoreSuffix();
const storeCode = getActiveStoreCode();
const routerBasenameProp = storeSuffix === '' ? {} : { basename: `/${storeSuffix}` };

loadableReady()
  .then(() => apolloClientWeb({ initialState, clientApolloSchema, apolloClientConfig: clientConfig.apolloClient }))
  .then(apolloClient => i18nFactory(i18nConfig).then(i18next => ({ apolloClient, i18next })))
  .then(({ apolloClient, i18next }) => {
    const markup = (
      <ApolloProvider client={apolloClient}>
        <HelmetProvider>
          <I18nProvider i18n={i18next}>
            <LoadingBarContextProvider>
              <BrowserRouter {...routerBasenameProp}>
                <StoreProvider suffix={storeSuffix} code={storeCode}>
                  <DatadogIntegration>
                    <React.Fragment>
                      <HtmlHead htmlLang={i18nConfig.lng} />
                      <App />
                    </React.Fragment>
                  </DatadogIntegration>
                </StoreProvider>
              </BrowserRouter>
            </LoadingBarContextProvider>
          </I18nProvider>
        </HelmetProvider>
      </ApolloProvider>
    );

    renderApp(markup, document.getElementById('root'));
  });

if (module.hot) {
  module.hot.accept();
}
