import { datadogRum } from '@datadog/browser-rum';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';

export const DatadogIntegration = withStoreConfiguration(({ storeConfiguration, children }) => {
  const datadog = storeConfiguration?.asam_tracking?.datadog;
  let ucUserInteraction = localStorage?.uc_user_interaction;

  const datadogConfig = {
    enabled: parseInt(datadog?.enabled, 10),
    env: datadog?.env,
    applicationId: datadog?.application_id,
    clientToken: datadog?.client_token,
    site: datadog?.site,
    service: datadog?.service,
    sampleRate: parseInt(datadog?.sample_rate, 10),
    replaySampleRate: parseInt(datadog?.replay_sample_rate, 10),
    trackInteractions: datadog?.track_interactions,
    defaultPrivacyLevel: datadog?.default_privacy_level
  };

  const ignoreConsent = parseInt(storeConfiguration?.asam_tracking?.datadog?.ignore_consent, 10);

  const initDataDog = () => {
    if (datadogConfig?.enabled === 1) {
      datadogRum.init(datadogConfig);
      datadogRum.startSessionReplayRecording();
    }
  };

  const initDataDogConsentWrapper = (reload = false) => {
    if (typeof window !== 'undefined' && window?.UC_UI?.getServicesBaseInfo()) {
      const functional = window?.UC_UI?.getServicesBaseInfo()?.find(item => item?.categorySlug === 'functional');
      if (functional?.consent?.status) initDataDog();
      if (!functional?.consent?.status && reload && ucUserInteraction === 'true') window.location.reload();

      ucUserInteraction = localStorage?.uc_user_interaction;
    }
  };

  window.addEventListener(
    'UC_UI_CMP_EVENT',
    e => {
      if (e?.detail?.type === 'ACCEPT_ALL' || e?.detail?.type === 'SAVE' || e?.detail?.type === 'DENY_ALL')
        initDataDogConsentWrapper(true);
    },
    false
  );

  window.addEventListener(
    'UC_UI_INITIALIZED',
    () => {
      initDataDogConsentWrapper();
    },
    { once: true }
  );

  if (ignoreConsent) {
    initDataDog();
  }

  return children;
});
